<template>
  <div class="list-page">
    <el-button class="top-btn" size="small" type="primary" @click="handleAdd"> 新增</el-button>
    <list-filter v-model="filterForm" :uploadFilter="ok" />
    <div class="list-content" v-loading="loading">
      <el-button size="small" type="danger" style="margin-top: 20px;" class="top-btn" @click="handleDel">删除</el-button>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%" @selection-change="handleSelectionChange"
        @sort-change="sortChange">
        <!-- 勾选 -->
        <el-table-column class="select-check" fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="330" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="text" size="small" @click="editRow(scope.row)">
                编辑
              </el-button>
              <el-button type="text" size="small" @click="setBadgeStatus(scope.row)">{{ scope.row.status ===
                1 ? "关闭" : "开启" }}</el-button>
              <el-button type="text" size="small" @click="presentDadge(scope.row, false)">
                查看领取用户
              </el-button>
              <el-button type="text" size="small" @click="presentDadge(scope.row, true)">赠送勋章</el-button>
              <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''"
            :sortable="th.sortable" :key="index">
            <template slot-scope="{ row }">
              <div v-if="th.prop == 'icon'">
                <list-image v-if="row[th.prop]" fit="cover" :width="40" border-radius="50%" :src="row[th.prop]" />
                <list-image v-else fit="cover" :width="40" border-radius="50%" src="" />
              </div>
              <!-- 排序输入框 -->
              <div v-else-if="th.prop == 'status_text'">
                <ListStatusText :text="row.status_text" :type="row.status === 1
                  ? 'success'
                  : 'info'
                  "></ListStatusText>
              </div>
              <!-- 普通 -->
              <span v-else>{{ row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <GiftDadgeDialog v-model="dialogGiftVisibl" :id="id" :isGift="isGift" :reflashList="reflashList" />
  </div>
</template>

<script>
import listFilter from "../components/ListFilter";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import ListImage from "@/base/components/List/ListImage";
import ListStatusText from "@/base/components/List/ListStatusText";
import GiftDadgeDialog from '../components/GiftDadgeDialog.vue'
import { badgeIndex, badgeDelete, badgeChangeStatus } from '../api/index'
export default {
  data() {
    return {
      loading: false, //加
      dialogGiftVisibl: false, // 赠送勋章弹窗
      id: '',// 勋章id
      isGift: false,// 赠送或者删除
      thead: [
        { label: "ID", prop: "id", minWidth: 90 },
        { label: "勋章名称", prop: "name", minWidth: 110 },
        { label: "勋章图标", prop: "icon", minWidth: 90, },
        {
          label: "达成条件",
          prop: "reach_type_text",
          minWidth: 120,
        },
        {
          label: "次数",
          prop: "users_count",
          minWidth: 80,
        },
        {
          label: "状态",
          prop: "status_text",
          minWidth: 80,
        },
        {
          label: "推荐渠道",
          prop: "msg_type_text",
          minWidth: 130,

        },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 180,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        create_start_time: -1, //开始时间
        create_end_time: -1, //结束时间
        status: -1,
        reach_type: -1,
        msg_type: -1,
        users_count_max: -1,
        users_count_min: -1,
        users_count: -1,
        page_size: 15, //每页数据量
      },
      pageData: {
        page_size: 15,
      },
      tbody: [],
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
    };
  },
  methods: {
    // 获取勋章列表
    getDadgeIndex(pageData) {

      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      console.log(pageData, 1);
      badgeIndex(pageData)
        .then((res) => {
          const { data } = res;
          data.data.forEach((item) => {
            item.is_show = item.is_show ? true : false;
          });
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
          console.log(2);
        })
        .catch((err) => {
          console.log(3);
          this.loading = false;
        });
    },
    //新增
    handleAdd() {
      // let routerData = this.$router.resolve({
      //   name: "AddJournal",
      //   params: { id: 0, type: "add" },
      // });
      // window.open(routerData.href, "_blank");
      this.$router.push({
        name: "AddBadge",
      });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      console.log(selection);
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.loading = true;
            badgeDelete({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.reflashList();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch((err) => { });

      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 修改勋章状态
    setBadgeStatus(row) {
      this.loading = true;
      console.log(row);
      badgeChangeStatus({ id: row.id, status: row.status === 1 ? 0 : 1 }).then((res) => {
        this.$message.success(res.msg);
        this.reflashList();
      })
        .catch((err) => {
          this.loading = false;
        });
    },


    // 数据删除
    handleDelete(id) {
      console.log(id);
      this.$msgbox
        .confirm(`确定要将${id.name}勋章删除吗？`, "提示", {
          type: "error",
        })
        .then((res) => {
          this.loading = true;
          badgeDelete(id)
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
    },
    // 赠送勋章&&查看领取用户
    presentDadge(row, is) {
      this.isGift = is
      this.id = row.id
      this.dialogGiftVisibl = true
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "EditBadge",
        params: { id: row.id, type: "edit" },
      });
    },
    //表格排序
    sortChange(column) {
      console.log(column);
      this.filterForm.is_desc = this.filterForm.order_by === 1
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      console.log(this.filterForm.order_by);
      this.getDadgeIndex(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getDadgeIndex({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getDadgeIndex({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      if (key === "sort") {
        data[key] = e[key];
      } else {
        data[key] = e[key] ? 1 : 0;
      }
      this.loading = true;
      // if (key === "is_show") {
      //   // 更新显示状态
      //   updateShow(data)
      //     .then((res) => {
      //       this.$message.success(res.msg);
      //       this.reflashList();
      //     })
      //     .catch(() => {
      //       this.refreshList();
      //     });
      // } else {
      // 更新排序状态
      updateSort(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch(() => {
          this.refreshList();
        });
      // }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getDadgeIndex(pageData);
    },
  },
  created() {
    if (this.$route.params.tab) {
      this.filterForm.tab = this.$route.params.tab;
    }
    this.getDadgeIndex({ ...this.filterForm, page: 1 }); //获取列表数据
  },
  components: { listFilter, Pagination, PopoverInputEdit, ListImage, ListStatusText, GiftDadgeDialog },
};
</script>

<style lang="scss" scoped></style>
