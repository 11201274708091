import api from "@/base/utils/request";

// 勋章列表
export const badgeIndex = data => {
  return api({
    url: "/admin/badge/badge/index",
    method: "post",
    data
  });
};

// 勋章详情
export const badgeDetail = data => {
  return api({
    url: "/admin/badge/badge/detail",
    method: "post",
    data
  });
};

// 勋章保存
export const badgeSave = data => {
    return api({
      url: "/admin/badge/badge/save",
      method: "post",
      data
    });
  };

// 勋章删除
export const badgeDelete= data => {
    return api({
      url: "/admin/badge/badge/delete",
      method: "post",
      data
    });
  };
  //  修改勋章状态
  export const badgeChangeStatus= data => {
    return api({
      url: "/admin/badge/badge/changeStatus",
      method: "post",
      data
    });
};
// 勋章领取用户列表
export const badgeDadgeUsers= data => {
  return api({
    url: "/admin/badge/badgeUser/index",
    method: "post",
    data
  });
};
// 颁发勋章
export const badgeDadgeUsersReward= data => {
  return api({
    url: "/admin/badge/badgeUser/reward",
    method: "post",
    data
  });
};
// 删除用户勋章
export const badgeDadgeUsersDelete= data => {
  return api({
    url: "/admin/badge/badgeUser/delete",
    method: "post",
    data
  });
};
//获取选择会员列表
export const SearchMemberList = data => {
  return api({
    url: "/admin/cyc/user/search",
    method: "post",
    data,
    notCancel: true
  });
};
// 获取架构职务
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
    notCancel: true

  });
};
// 勋章模板

export const templates = ()=> {
  return api({
    url: "/admin/badge/badge/templates",
    method: "post",
  });
};